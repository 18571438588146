<template>
    <div class="modal__students" v-if="employees.length > 0">
        <div class="modal__title subtitle js-error-modal-title">Добавить обучающегося из списка «Мои сотрудники»</div>
        <div class="modal__description js-error-modal-description">Выберите из списка обучающегося и добавьте его на курс</div>
        <div class="list-add" data-simplebar>
            <Employee
                v-for="(employee, index) in employees"
                :key="index"
                :index="index"
                :employee="employee"
                :formId="formId"
                @close-modal="this.$emit('closeModal')"
            />
        </div>
    </div>
    <div class="modal__message" v-else>
        <div class="modal__title subtitle js-error-modal-title">У вас еще нет обучаемых сотрудников</div>
        <div class="modal__description js-error-modal-description">Добавьте обучаемых и заполните информацию о них. В дальнейшем они появятся в вашем личном кабинете в разделе “Мои сотрудники”. При покупке других курсов вы сможете добавить от сюда информацию об учениках.</div>
    </div>
</template>

<script>
import Employee from '@/components/modals/application/employee'
import "simplebar-vue/dist/simplebar-vue.js";

export default {
    name: 'Employees',

    components: {
        Employee
    },

    props: {
        employees: {
            type: Array,
            default() {
                return []
            }
        },

        formId: {
            type: String,
            required: true
        }
    }
}
</script>
