<template>
    <div class="sidebar-courses js-sidebar" :class="{ 'active' : isActiveSideBar }" v-if="courses.length">
        <button class="btn sidebar-courses__btn" @click.prevent="isActiveSideBar = !isActiveSideBar">
            <svg class="icon icon-right ">
                <use xlink:href="#right"></use>
            </svg>
        </button>
        <div class="sidebar-courses__body">
            <div class="sidebar-courses__title subtitle">Выбрать из добавленных курсов</div>
            <div class="sidebar-courses__items">
                <div class="card-course" v-for="(course, index) in courses" :key="index">
                    <div class="card-course__header">
                        <div>Курс {{ course.course.code }}</div>
                        <button class="btn btn-delete" @click.prevent="deleteCourse(course.course.id)">
                            <img src="/img/icons/XCircle.svg"/>
                            Удалить
                        </button>
                    </div>
                    <div class="card-course__title">{{ course.subcategory.name }}</div>
                    <div class="card-course__items">
                        <div class="card-course__item">
                            <div class="text-gray">Тип обучения:</div>
                            {{ course.type.name }}
                        </div>
                        <div class="card-course__item">
                            <div class="text-gray">Специализация обучения:</div>
                            {{ course.category.name }}
                        </div>
                        <div class="card-course__item">
                            <div class="text-gray">Кол-во часов обучения:</div>
                            {{ course.price.hours }}
                        </div>
                        <div class="card-course__item">
                            <div class="text-gray">Обучающихся:</div>
                            {{ course.numberLearner }}
                        </div>
                    </div>
                    <button class="btn btn-white" @click.prevent="selectAddedCourse(course)">Добавить выбранный курс</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
    setup(props, {emit}) {
        const isActiveSideBar = ref(true)
        const courses = ref(localStorage.getItem("courses") ? JSON.parse(localStorage.getItem("courses")) : [])
        const deleteCourse = courseId => {
            const index = courses.value.findIndex(item => item.course.id === courseId)
            courses.value.splice(index, 1)
            localStorage.setItem("courses", JSON.stringify(courses.value));
        }

        const selectAddedCourse = course => emit('update:form', course)

        return { isActiveSideBar, courses, deleteCourse, selectAddedCourse }
    },
}
</script>
