<template>
    <div data-input-group="checkbox" class="input-group" :class="{ 'input-group--error': !!this.error }">
        <div class="input-group__label">{{ label }}</div>
        <div class="input-group__wrapper">
            <div class="custom-select custom-select--courses" :class="getClasses" ref="select">
                <div class="custom-select__header" @click="toggleShow">
                    <span class="custom-select__selected">
                        <span class="strong" v-if="resultCode">{{ resultCode }}.</span>
                        {{ resultLabel }}
                    </span>
                    <span class="custom-select__arrow">
                        <svg class="icon icon-top-bold ">
                            <use xlink:href="#top-bold"></use>
                        </svg>
                    </span>
                </div>
                <div class="custom-select__body">
                    <div class="custom-select__body-list" ref="body">
                        <label class="custom-select__option" v-for="{ id, name, code } in options" :key="id">
                            <input
                                v-model="selected"
                                type="checkbox"
                                :true-value="id"
                                :false-value="null"
                            >
                            <span class="custom-select__label">
                                <span class="strong" v-if="code">{{ code }}.</span>
                                {{ name }}
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="input-group__error">{{ error }}</div>
    </div>
</template>

<script>
import SimpleBar from 'simplebar'

export default {
    props: {
        label: {
            required: true
        },

        options: {
            default: []
        },

        modelValue: {
            default: null
        },

        error: {
            default: ''
        }
    },

    data() {
        return {
            isOpen: false,
            selected: this.modelValue
        }
    },

    watch: {
        selected() {
            this.$emit('update:modelValue', this.selected)
            this.isOpen = false
        },

        modelValue(newValue) {
            this.selected = newValue
        }
    },

    methods: {
        toggleShow() {
            this.isOpen = !this.isOpen
        },

        closeClickNotCurrentArea(event) {
            const area = this.$refs.select
            const target = event.target
            const isSelectArea = target === area || area.contains(target)

            if(!isSelectArea && this.isOpen) this.isOpen = false
        },

        reset() {
            this.selected = null
            this.isOpen = false
        }
    },

    computed: {
        getClasses() {
            return {
                'active': this.isOpen,
                'custom-select--not-default': !!this.selected
            }
        },

        resultLabel() {
            if (!this.selected) return this.label
            const selectedOption = this.options.find(option => option.id === this.selected)

            return selectedOption.name
        },

        resultCode() {
            if (!this.selected) return null
            const selectedOption = this.options.find(option => option.id === this.selected)

            return selectedOption.code
        }
    },

    mounted() {
        document.addEventListener('click', this.closeClickNotCurrentArea, false)

        const form = this.$refs.select.closest('form')
        if (form) form.addEventListener('reset', this.reset, false)

        new SimpleBar(this.$refs.body)
    },

    beforeUnmount() {
        document.removeEventListener('click', this.closeClickNotCurrentArea, false)

        const form = this.$refs.select.closest('form')
        if (form) form.removeEventListener('reset', this.reset, false)
    }
}
</script>
