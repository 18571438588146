<template>
    <Form
        v-for="(form, index) in forms"
        :key="form.key"
        :formNumber="index"
        :form="form"
        :categories="categories"
        :employees="employeesList"
    />

    <div class="section-header" @click="onCreate">
        <div class="card-custom card-custom--course">
            <span class="card-custom__title subtitle">Добавить дополнительно курс обучения</span>
            <img src="/img/icons/plus-circle.svg">
        </div>
    </div>

    <div class="form-big">
        <div class="form-big__wrapper">
            <div class="row-grid row-grid--form">
                <div class="subtitle">Итого стоимость обучения с учетом доставки:</div>
                <div class="subtitle text-blue">{{ fullPrice }} руб.</div>
            </div>
            <div class="btn btn-white btn-white--square" @click="scrollToTop">
                <svg class="icon icon-arrow-top ">
                    <use xlink:href="#arrow-top"></use>
                </svg>
            </div>
        </div>
        <div class="form-big__submit">
            <div class="btn-wrapper">
                <button
                    type="submit"
                    class="btn btn-red"
                    :disabled="!(isCourseFilled && isUsersFilled)"
                    @click="onSubmit"
                >
                    Отправить
                </button>
                <div class="tooltip" v-if="!(isCourseFilled && isUsersFilled)">
                    <div class="tooltip__text">Необходимо заполнить {{ tooltipForBtnSubmit }}</div>
                </div>
            </div>
        </div>
        <div class="form-big__description text-gray">
            Нажимая  кнопку  «Отправить»,  Вы  подтверждаете,  что  все  указанные  данные  принадлежат лично Вам и (или) Вы имеете необходимое
            согласие на передачу таких данных, признаете, что внимательно и в полном объеме ознакомились с&nbsp;<a href="/">Политикой конфиденциальности персональных данных,&nbsp;</a>текст и условия которых Вам понятны и Вы их принимаете в полном объеме, а также даете свое согласие на обработку персональных
            данных без оговорок и ограничений в рамках предоставления образовательных услуг.
        </div>
    </div>

    <SideBarCourses @update:form="selectedCourses" />
</template>

<script>
import store from '@/store/index'
import Form from '@/pages/application/courses/components/Form'
import SideBarCourses from '@/pages/application/courses/components/SideBarCourses'

export default {
    name: 'Courses',

    components: {
        Form,
        SideBarCourses
    },

    data() {
        return {
            initialKey: null,
            isModalOpen: false,
            students: [],
            employeesList: [],
            startDate: new Date().toISOString().slice(0, 10)
        }
    },

    computed: {
        categories() {
            return this.$store.getters.getCategories
        },

        forms() {
            return this.$store.getters.getApplications
        },

        isCourseFilled() {
            return this.$store.getters.getApplications.every(el => el.course.selectedCourseId !== null)
        },

        isUsersFilled() {
            return this.$store.getters.getApplications.every(el => el.students.length !== 0)
        },

        fullPrice() {
            return this.$store.getters.getFullPrice
        },

        tooltipForBtnSubmit() {
            if (!this.isCourseFilled && !this.isUsersFilled) return 'все данные в шаге 2 и 3'
            else {
                if (!this.isUsersFilled) return 'информацию об обучающихся'
                else return ''
            }
        },
        isErrorbyStudent() {
            return this.$store.state.application.errorStudents
        }
    },

    methods: {
        selectedCourses(course) {
            const index = this.forms.findIndex(item => !item.course.selectedCourseId)
            if (index === -1) {
                store.commit('APPLICATION_CREATE_COURSE')
                store.commit('APPLICATION_FILL_COURSE', {
                    index: this.forms.length - 1,
                    course: {
                        selectedCourseId: course.course.id,
                        startDate: this.startDate,
                        endDate: this.getEndDate(course.price.hours),
                        otherDates: false,
                        priceId: course.price.id,
                        price: null,
                    }
                })
                this.showSelectedCourse(this.forms.length - 1, course)
            }
            else {
                store.commit('APPLICATION_FILL_COURSE', {
                    index: index,
                    course: {
                        selectedCourseId: course.course.id,
                        startDate: this.startDate,
                        endDate: null,
                        otherDates: false,
                        priceId: course.price.id,
                        price: null,
                    }
                })
                this.showSelectedCourse(index, course)
            }
        },
        getEndDate(time) {
            const startData =  new Date(this.startDate)
            const days = Math.ceil(time / 6,5)
            const endData = startData.setDate(startData.getDate() + days)
            return new Date(endData).toISOString().slice(0, 10)
        },
        showSelectedCourse(index, course) {
            store.dispatch('APPLICATION_GET_TYPES', { formId: index, id: course.category.id }).then(() => {
                store.commit('APPLICATION_SET_DATA_FORM', {
                    id: index,
                    key: 'selectedCategory',
                    data: course.category.id
                })
                store.commit('APPLICATION_SET_DATA_FORM', {
                    id: index,
                    key: 'selectedType',
                    data: course.type.id
                })
            })
            store.dispatch('APPLICATION_GET_COURSES', { formId: index, id: course.type.id }).then(() => {
                store.commit('APPLICATION_SET_DATA_FORM', {
                    id: index,
                    key: 'selectedSubCategory',
                    data: course.subcategory.id
                })
                store.commit('APPLICATION_SET_DATA_FORM', {
                    id: index,
                    key: 'selectedCourse',
                    data: course.course.id
                })
            })
            store.dispatch('APPLICATION_GET_COURSE_TIME', { formId: index, id: course.course.id }).then(() => {
                store.commit('APPLICATION_SET_DATA_FORM', {
                    id: index,
                    key: 'selectedTimeId',
                    data: course.price.id
                })
                store.commit('APPLICATION_SET_DATA_FORM', {
                    id: index,
                    key: 'selectedTime',
                    data: course.price.hours
                })
            })
        },
        addNewForm() {
            store.commit('APPLICATION_CREATE_COURSE')
        },

        onCreate() {
            this.addNewForm()
        },

        onSubmit() {
            this.makeResponseArr()
            this.getErrorbyStudent()

            if (Object.keys(this.isErrorbyStudent).length === 0)
                store.dispatch('APPLICATION_SEND_COURSES', {courses: this.makeResponseArr()})
                    .then(() => {
                        this.$router.push({ name: 'courses-email' })
                    })
                    .catch(error => {
                        store.commit('APPLICATION_SET_ERRORS', error.response.data.errors)
                    })
        },

        getErrorbyStudent() {
            const errorStudents = {}
            this.forms.forEach(application => {
                application.students.forEach(student => {
                    errorStudents[student.key] = []
                    if (student.fields) {
                        if (!student.fields['surname']) errorStudents[student.key].push('surname')
                        if (!student.fields['name']) errorStudents[student.key].push('name')
                        if (!student.fields['birthday']) errorStudents[student.key].push('birthday')
                    } else errorStudents[student.key].push('name', 'surname', 'birthday')
                    if (errorStudents[student.key].length === 0) delete errorStudents[student.key]
                })
            })
            this.$store.commit("APPLICATION_SET_ERRORS_STUDENTS", errorStudents)
        },

        scrollToTop() {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        },

        makeResponseArr() {
            const request = []
            const applications = [...this.$store.state.application.applications]

            applications
                .filter(application => application.course.selectedCourseId)
                .forEach(application => {
                    const record = {
                        course_id: application.course.selectedCourseId,
                        start_date: application.course.startDate,
                        end_date: application.course.endDate,
                        other_dates: application.course.otherDates,
                        price_id : application.course.priceId
                    }

                    record.students = application.students
                        .filter(student => student.fields)
                        .map(student => {
                            const checkedTypesFields = student.fields
                            for (const key in student.fields) {
                                if (typeof student.fields[key] !== 'string') delete checkedTypesFields[key]
                            }

                            return {
                                user_id : Number.isInteger(student.key) ? student.key : null,
                                fields : checkedTypesFields
                            }
                        })

                    request.push(record)
                })

            return request
        }
    },

    beforeRouteEnter(to, from, next) {
        store.dispatch('PERSONAL_FETCH_USER_DATA')
            .then(response => {
                if (response.data.role === 'Обучаемый')
                    store.dispatch('AUTH_LOGOUT').then(() => next('/application'))
                else store.dispatch('APPLICATION_GET_CATEGORIES').then(() => next())
            })
    },

    mounted() {
        this.initialKey = window.makeId()

        store.commit('APPLICATION_INIT_COURSE', this.initialKey)
        store.dispatch('APPLICATION_GET_EMPLOYEES')
            .then(response => {
                this.employeesList = response.data
            })
    },
}
</script>
