<template>
    <div class="card-border card-border--person-form">
        <div class="form-big">
            <div class="form-big__header">
                <div class="form-big__title text-blue text-big">Обучаемый №{{number + 1}}</div>
                <div class="btn btn-delete" @click="onDelete">
                    <img src="/img/icons/XCircle.svg">
                    Удалить
                </div>
            </div>
            <div class="form-big__row">

                <template v-for="(input, index) in fields" :key="index">
                    <InputFile
                        v-if="input.type === 'file'"
                        :label="input.name"
                        :code="input.code"
                        :tooltip="input.tooltip"
                        :is-required="input.required"
                        v-model="studentInfo[input.code]"
                        @change="onSaveCertificate(input.code)"
                    />
                    <InputDate
                        v-if="input.type === 'date'"
                        :label="input.name"
                        :isError="!!getErrorByCode(input.code)"
                        :error="getErrorByCode(input.code)"
                        v-model="studentInfo[input.code]"
                        @change="onChange"
                    />
                    <Input
                        v-if="input.type !== 'date' && input.type !== 'file'"
                        :code="input.code"
                        :type="input.type"
                        :label="input.name"
                        :tooltip="input.tooltip"
                        :isError="!!getErrorByCode(input.code)"
                        :error="getErrorByCode(input.code)"
                        :is-required="input.required"
                        v-model="studentInfo[input.code]"
                        @change="onChange"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store/index'
import Input from '@/components/form/input'
import InputFile from '@/components/form/input-file'
import InputDate from '@/components/form/input-date'

export default {
    name: 'StudentCard',

    components: {
        Input,
        InputFile,
        InputDate
    },

    props: {
        index: [String, Number],
        formId: String,
        number: Number
    },

    data() {
        return {
            studentInfo: {}
        }
    },

    computed: {
        fields() {
            return this.$store.getters.getApplications.find(form => form.key === this.formId).fields
        },

        studentFields() {
            return this.$store.getters.getApplications.find(form => form.key === this.formId)
                .students.find(student => student.key === this.index)
        },

        isEmployer() {
            return typeof this.index === 'number'
        },

        formIndex() {
            return this.$store.getters.getFormIndexByFormId(this.formId)
        },

        errorStudents() {
            return this.$store.state.application.errorStudents[this.index]
        }
    },

    methods: {
        onDelete() {
            store.commit('APPLICATION_REMOVE_STUDENT', {
                formId: this.formId,
                studentId: this.index
            })
        },

        onChange() {
            store.commit('APPLICATION_SET_STUDENT', {
                formId: this.formId,
                studentId: this.index,
                student: this.studentInfo
            })
        },

        onSaveCertificate(code) {
            this.$store.dispatch('PERSONAL_SET_CERTIFICATE', this.studentInfo[code])
                .then(response => {
                    this.studentInfo[code] = response.data
                    this.onChange()
                })
        },

        getErrorByCode(code) {
            let errors = null
            if (this.errorStudents)
                errors = this.errorStudents.find(item => item === code)
                return errors ? ['Это поле обязательно для заполнения'] : null
        }
    },
    mounted() {
        if(this.isEmployer) {
            this.fields.forEach(field => {
                this.studentInfo[field.code] = this.studentFields.fields[field.code]
            })
        }
    },
}
</script>
