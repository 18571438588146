<template>
    <div class="list-add__item">
        <div class="list-add__wrapper">
            <div class="list-add__count">{{ index + 1 }}</div>
            <div class="list-add__name">{{ employee.username }}</div>
        </div>
        <button
            class="btn btn-round btn-round--bule"
            @click="createEmployee"
        >
            Добавить
        </button>

        <!-- <button class="btn btn-round btn-round--red">Убрать</button> -->
    </div>
</template>

<script>
import store from '@/store/index'

export default {
    name: 'Employee',

    props: {
        employee: {
            type: Object,
            required: true
        },

        index: {
            type: Number,
            required: true
        },

        formId: {
            type: String,
            required: true
        }
    },

    methods: {
        createEmployee() {
            store.commit('APPLICATION_CREATE_STUDENT', {
                formId: this.formId,
                student: {
                    key: this.employee.id,
                    fields: {
                        birthday: this.employee.birthday,
                        name: this.employee.name,
                        surname: this.employee.surname,
                        middle_name: this.employee.middle_name,
                        snils: this.employee.snils,
                        certificate: this.employee.certificate,
                    }
                }
            })

            this.$emit('closeModal')
        }
    }
}
</script>
