<template>
    <Directions
        :span="formNumber === 0 ? 'Шаг 2.' : null"
        :title="formNumber === 0 ? 'Выберите направление обучения' : 'Дополнительный курс обучения'"
        :categories="categories"
        :formId="form.key"
        :formNumber="formNumber"
    />

    <Students
        :span="formNumber === 0 ? 'Шаг 3.' : null"
        :title="formNumber === 0 ? 'Заполните информацию об обучающихся' : 'Заполните информацию об обучающихся'"
        :description="'Введите все необходимые данные, чтобы добавить обучающегося на курс и сгенерировать для него логин для входа в его личный кабинет. СНИЛС и документ об образовании обучающегося можно будет добавить или отредактировать позднее.'"
        :alert="'Будьте внимательны! При неправильном заполнении СНИЛС, выданный квалификационный документ будет признан недействительным'"
        :formId="form.key"
        :employees="employees"
    />
</template>

<script>
import Directions from '@/pages/application/courses/components/Directions'
import Students from '@/pages/application/courses/components/Students'

export default {
    name: 'Form',

    components: {
        Directions,
        Students
    },

    props: {
        categories: {
            type: Array,
            required: true
        },

        form: {
            type: Object,
            required: true
        },

        formNumber: {
            type: Number
        },

        employees: {
            type: Object
        }
    }
}
</script>
