<template>
    <p
        class="subtitle"
        v-if="title && formNumber === 0"
    >
        <span class="subtitle text-red" v-if="span">
            {{ span }}&ensp;
        </span>
        {{ title }}
    </p>

    <div class="form-head" v-else>
        <p class="subtitle" v-if="title">{{ title }}</p>
		<div class="btn btn-delete" @click="deleteApplication">
			<img src="/img/icons/XCircle.svg">
			Удалить
		</div>
    </div>

    <div class="form-big">
        <Select
            :label="`Направление обучения`"
            :options="categories"
            v-model="selectedCategory"
        />
        <Select
            v-if="types.length"
            :label="`Тип обучения`"
            v-model="selectedType"
            :options="types"
        />
        <Select
            v-if="subcategories.length"
            :label="`Деятельность/категория/специализация обучения`"
            :options="subcategories"
            v-model="selectedSubCategory"
        />
        <Select
            v-if="courses.length"
            :label="`Выберите курс из предоставленного списка`"
            :options="courses"
            v-model="selectedCourse"
        />
        <Select
            v-if="times.length"
            :label="`Выберите количество часов обучения`"
            :options="times"
            v-model="selectedTimeId"
        />

        <p>При необходимости, вы можете вручную изменить дату начала и завершения обучения на поздние сроки</p>

        <div class="form-big__row form-big__row--dual">
            <InputDate
                :label="'Начать обучение с'"
                :isError="!!getErrorByCode('start_date')"
                :error="getErrorByCode('start_date')"
                :minDate="startDate"
                v-model="startDate"
            />
            <InputDate
                :label="'Завершить обучение'"
                :isError="!!getErrorByCode('end_date')"
                :error="getErrorByCode('end_date')"
                :minDate="minEndDate"
                v-model="endDate"
            />
        </div>

        <label class="checkbox">
            <input
                name="checkbox"
                type="checkbox"
                value="Y"
                v-model="otherDates"
                @change="addOtherDates"
            >
            <span class="checkbox__indicator">
            <svg class="icon icon-check ">
                <use xlink:href="#check"></use>
            </svg></span><span class="checkbox__description">Требуется согласовать иные сроки обучения&nbsp;<span class="text-gray">(Наш специалист свяжется с вами в ближайшее время)</span></span>
        </label>
    </div>
</template>

<script>
import store from '@/store/index'

import Select from '@/components/form/select'
import InputDate from '@/components/form/input-date'

export default {
    name: 'Directions',

    components: {
        Select,
        InputDate
    },

    props: {
        title: {
            type: String,
            required: true
        },

        span: {
            type: String
        },

        categories: {
            type: Array,
            required: true
        },

        formId: {
            type: String,
            required: true
        },

        formNumber: {
            type: Number
        }
    },

    data() {
        return {
            startDate: new Date().toISOString().slice(0, 10),
            endDate: null,
            otherDates: false,
            minEndDate: new Date().toISOString().slice(0, 10),
        }
    },

    computed: {
        reversedStartDate() {
            return this.reversedDate(this.startDate)
        },

        reversedEndDate() {
            return this.reversedDate(this.endDate)
        },

        formIndex() {
            return this.$store.getters.getFormIndexByFormId(this.formId)
        },

        studentsLength() {
            return this.$store.getters.getApplications.find(application => application.key === this.formId).students.length
        },
        dataForForms() {
            return this.$store.state.application.dataForForms[this.formIndex]
        },
        types() {
            return this.dataForForms.types
        },
        subcategories() {
            return this.dataForForms.subcategories
        },
        courses() {
            return this.dataForForms.courses
        },
        times() {
            return this.dataForForms.times
        },
        selectedCategory: {
            get() {
                return this.dataForForms.selectedCategory
            },
            set(value) {
                this.$store.commit('APPLICATION_SET_DATA_FORM', {
                    id: this.formIndex,
                    key: 'selectedCategory',
                    data: value
                })
            }
        },
        selectedSubCategory: {
            get() {
                return this.dataForForms.selectedSubCategory
            },
            set(value) {
                this.$store.commit('APPLICATION_SET_DATA_FORM', {
                    id: this.formIndex,
                    key: 'selectedSubCategory',
                    data: value
                })
            }
        },
        selectedType: {
            get() {
                return this.dataForForms.selectedType
            },
            set(value) {
                this.$store.commit('APPLICATION_SET_DATA_FORM', {
                    id: this.formIndex,
                    key: 'selectedType',
                    data: value
                })
            }
        },
        selectedCourse: {
            get() {
                return this.dataForForms.selectedCourse
            },
            set(value) {
                this.$store.commit('APPLICATION_SET_DATA_FORM', {
                    id: this.formIndex,
                    key: 'selectedCourse',
                    data: value
                })
            }
        },
        selectedTimeId: {
            get() {
                return this.dataForForms.selectedTimeId
            },
            set(value) {
                this.$store.commit('APPLICATION_SET_DATA_FORM', {
                    id: this.formIndex,
                    key: 'selectedTimeId',
                    data: value
                })
            }
        },
        selectedTime: {
            get() {
                return this.dataForForms.selectedTime
            },
            set(value) {
                this.$store.commit('APPLICATION_SET_DATA_FORM', {
                    id: this.formIndex,
                    key: 'selectedTime',
                    data: value
                })
            }
        }
    },

    watch: {
        selectedCategory (newValue) {
            store.dispatch('APPLICATION_GET_TYPES', {formId: this.formNumber, id: newValue})
        },

        selectedType (newValue) {
            store.dispatch('APPLICATION_GET_COURSES', {formId: this.formNumber, id: newValue})
        },

        selectedSubCategory (newValue) {
            if(!newValue) return;

            const course = this.subcategories.find(subcategory => subcategory.id === this.selectedSubCategory).courses

            store.commit('APPLICATION_SET_DATA_FORM_COURSES', {
                id: this.formIndex,
                data: course
            })
        },

        selectedCourse (newValue) {
            store.dispatch('APPLICATION_GET_COURSE_TIME', {formId: this.formNumber, id: newValue})
            store.commit('APPLICATION_SET_COURSE_ID', {"formId": this.formId, "courseId": newValue})
            this.getFields()
            this.getCoursePrice()
        },

        selectedTimeId(newValue) {
            if (newValue) {
                this.$store.commit('APPLICATION_SET_DATA_FORM', {
                    id: this.formIndex,
                    key: 'selectedTime',
                    data: this.times.find(item => item.id === newValue).hours
                })
                this.addDaysToStartDate()
                this.onTimeSelect()
            }
        },

        studentsLength() {
            store.dispatch("APPLICATION_GET_PRICE", {
                price_id: this.selectedTimeId,
                students_count: this.studentsLength
            }).then(response =>
                store.commit("APPLICATION_SET_PRICE", {
                    formId: this.formId,
                    price: response.data.result_price,
                })
            )
        },
        startDate() {
            this.addStartDate()
        },
        endDate() {
            this.addEndDate()
        },
    },

    mounted() {
        this.addStartDate()
    },

    methods: {
        reversedDate(date) {
            return date && date.split('-').reverse().join('.')
        },
        addStartDate() {
            store.commit('APPLICATION_ADD_START_DATE', {
                formId: this.formId,
                startDate: this.startDate
            })
            if (this.selectedTime) this.addDaysToStartDate()
        },

        addDaysToStartDate() {
            const startData =  new Date(this.startDate)
            const days = Math.ceil(this.selectedTime / 6,5)
            const endData = startData.setDate(startData.getDate() + days)
            this.endDate = new Date(endData).toISOString().slice(0, 10)
            this.minEndDate = this.endDate
            this.addEndDate()
        },

        addEndDate() {
            store.commit('APPLICATION_ADD_END_DATE', {
                formId: this.formId,
                endDate: this.endDate
            })
        },

        addOtherDates() {
            store.commit('APPLICATION_ADD_OTHER_DATES', {
                formId: this.formId,
                otherDates: this.otherDates
            })
        },

        getFields() {
            store.dispatch('APPLICATION_GET_USER_FIELDS', {
                form: this.formId,
                request: {course_id: this.selectedCourse}
            })
        },

        getCoursePrice() {
            store.dispatch('APPLICATION_GET_COURSE_PRICE', {
                formId: this.formId,
                courseId: {
                    "courses": [this.selectedCourse]
                }
            })
        },

        onTimeSelect() {
            store.commit("APPLICATION_SET_COURSE_PRICE", {
                formId: this.formId,
                priceId: this.selectedTimeId,
            })
            store.dispatch("APPLICATION_GET_PRICE", {
                price_id: this.selectedTimeId,
                students_count: this.studentsLength
            }).then(response =>
                store.commit("APPLICATION_SET_PRICE", {
                    formId: this.formId,
                    price: response.data.result_price,
                })
            )
        },

		deleteApplication() {
			store.commit('APPLICATION_REMOVE_COURSE', this.formId)
		},

        getErrorByCode(code) {
            const errors = this.$store.getters.getErrorByCodeAndFormIndex(this.formIndex, code)
            return errors ? errors.join('</br>') : null
        }
    }
}
</script>
