<template>
    <div class="header-steps">
        <p class="subtitle" v-if="title"><span class="subtitle text-red" v-if="span">{{ span }}&ensp;</span>{{ title }}</p>
        <div class="header-steps__description" v-if="description">{{ description }}</div>
        <div class="text-wrapper text-wrapper--remark" v-if="alert">{{ alert }}</div>
    </div>

    <div class="courses-list courses-list----primary">
        <StudentCard
            v-for="(student, index) in students"
            :key="student.key"
            :index="student.key"
            :number="index"
            :formId="formId"
        />
    </div>

    <div class="grid-list grid-list--dual">
        <button
            type="button"
            class="btn btn-white"
            :disabled="isFieldsAvailable"
            @click="addNewStudent"
        >
            + Добавить нового обучаемого
        </button>
        <button
            type="button"
            class="btn btn-white"
            :disabled="isFieldsAvailable"
            @click="openPopup"
        >
            + Добавить из списка «Мои сотрудники»
        </button>
    </div>

    <BaseModal
        v-if="isModalOpen"
        :classes="employees.length > 0 ? '' : 'modal--error'"
        @close="closePopup"
    >
        <Employees
            :employees="employees"
            :formId="formId"
            @close-modal="closePopup"
        />
    </BaseModal>
</template>

<script>
import store from '@/store/index'

import StudentCard from '@/pages/application/courses/components/StudentCard'
import BaseModal from '@/components/modals/base-modal'
import Employees from '@/components/modals/application/employees'

export default {
    name: 'Students',

    components: {
        StudentCard,
        BaseModal,
        Employees
    },

    props: {
        span: String,
        title: String,
        description: String,
        alert: String,
        formId: String,
        employees: Object
    },

    emits: ['openPopup'],

    data() {
        return {
            isModalOpen: false
        }
    },

    computed: {
        isFieldsAvailable() {
            return !this.$store.getters.getApplications.find(application => application.key === this.formId).course.selectedCourseId
        },

        students() {
            return this.$store.getters.getApplications.find(application => application.key === this.formId).students
        }
    },

    methods: {
        addNewStudent() {
            store.commit('APPLICATION_CREATE_STUDENT', {
                formId: this.formId,
                student: {
                    key: window.makeId()
                }
            })
        },

        openPopup() {
            this.isModalOpen = true
        },

        closePopup() {
            this.isModalOpen = false
        }
    }
}
</script>
